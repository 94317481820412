@import '../../src/ui/style/colors';
@import '../../src/ui/style/typography';

.dashboardSideBar {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  position: relative;
  background-color: $color-background-light;
  display: flex;
  flex-direction: column;

  // The sidebar width is set in JS as we store it in a constant (ENLARGED_WIDTH)
  // which we use in other places. Ideally we should move this to CSS.
}

.sidebarButton {
  cursor: pointer;
  background-color: $color-background-light;
  height: 100%;
  font-size: 1rem;
  width: 100%;
  border: 0;
  justify-content: end;

  &:hover {
    background-color: $color-background-dark;
  }
}

.collapsed {
  display: flex;
  justify-content: stretch;

  .sidebarButton {
    padding: 0;
    display: flex;
    align-items: end;
  }

  // span targets the icon
  span {
    border-top: 1px solid $color-background-dark;
    width: 100%;
    padding: 8px 0;
  }
}

.sideBarFooter {
  border-top: 1px solid $color-background-dark;
  z-index: 50;
  height: 32px;
  flex-shrink: 0;

  .sidebarButton {
    display: flex;
    align-items: center;
    padding: 0 8px;
  }
}

.sideBarHeader {
  h4 {
    @include fontSmall;
    color: $color-black-70;
  }

  button {
    appearance: none;
    background: transparent;
    border: none;
    cursor: pointer;
    color: $color-black-70;
  }
}

.addNewDashboardButton {
  &:hover {

    button,
    span {
      color: $color-black-60;
      background: transparent;
    }
  }

  span {
    font-weight: 400;
    color: $color-black-70;
  }

  button {
    @include fontLight;
    color: $color-black-70;
    padding: 0;

    &:focus {
      box-shadow: none;
    }
  }
}

.dashboardsList {
  overflow-y: scroll;
  height: 100%;

  li {
    list-style: none;
  }
}

.DashboardsList__Item {
  position: relative;

  &:hover {
    .editTitleIcon {
      display: block;
    }

    .DashboardsList__Link__active {
      padding: 0.5rem 1.5rem 0.5rem 0.5rem;
    }
  }
}

.DashboardsList__Link {
  color: $color-black-100;
  position: relative;
  font-size: 1rem;
  display: flex;
  padding: 0.5rem;

  span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &:focus,
  &:hover,
  &:visited:hover {
    border-radius: 4px;
    background-color: $color-background-dark;
    color: $color-black-100;
  }
}

.DashboardsList__Link__active {
  position: relative;
  background-color: $color-background-dark;
  border-radius: 4px;

  &:hover {
    padding: 0.5rem 1.5rem 0.5rem 0.5rem;
  }

  textarea {
    color: $color-grey-100;
  }
}

.editTitleIcon {
  display: none;
  position: absolute;
  padding: 0;
  right: 0.5rem;
  top: 50%;
  transform: translateY(-50%);
  color: $color-grey-100;
  background: none;
  border: none;
  cursor: pointer;
}
